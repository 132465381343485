<template>
  <div>
    <label>
      <span v-if="titleHtmlify" v-html="urlify(placeholder)"></span>
      <span v-else class="no-block">{{placeholder}}</span>
    </label>
    <el-checkbox-group v-model="inputVal" class="d-flex">
    <el-checkbox
        v-for="{value} in options"
        :key="value"
        :label="value"
        :value="value"
        class="w-50"
    ></el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
// recently checkbox component only supports custom addtional questions page
import { urlify } from '@/utils/misc';

export default {
  name: 'CheckboxSP',
  props: {
    options: Array,
    value: Array,
    name: String,
    placeholder: String,
    titleHtmlify: Boolean,
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val);
    },
  },
  data() {
    return {
      urlify,
      inputVal: this.value,
    };
  },
};

</script>

<style lang="scss" scoped>
label {
  // position: absolute;
  // bottom: 5px;
  // left: 0;
  color: #909090;
  font-weight: 300;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
}
</style>
