<template>
  <div class="el-form-item">
    <div class="el-form-item__content">
      <el-select
          v-model="inputVal"
          class="form-control"
          :class="valueCheck ? 'has-text': null"
          placeholder=""
          :disabled="disabled"
          @blur="$emit('blur')"
      >
        <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label">
          <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-option-group>
      </el-select>
      <label :for="name" class="no-block">
        {{ placeholder }}
      </label>
    </div>
  </div>

</template>

<script>
export default {
  name: 'GroupedSelect',
  props: {
    options: Array,
    value: [String, Boolean],
    name: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueCheck() {
      if (this.inputVal === false) return true;
      return !!this.inputVal;
    },
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val);
    },
  },
  data() {
    return {
      inputVal: this.value,
    };
  },
};

</script>
<style lang="scss">
  li.el-select-dropdown__item {
    font-size: 0.875rem;
    &.selected {
      span {
        font-family: $fontBold;
      }

      &::before {
        border: none !important;
      }
    }
  }
</style>

<style lang="scss" scoped>

  ::v-deep .el-select-group__title {
    font-family: $fontMedium, Arial;
    color: black;
    font-size: 1em;
  }

  .el-select-group__wrap::after {
    height: 0 !important;
  }

  // new animated for selects
  ::v-deep .el-select {
    width: 100%;

    + label {
      position: absolute;
      top: 2px;
      left: 0;
      font-size: 0.875rem;
      color: #909090;
      font-weight: 300;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease-in-out;
    }
  }

  ::v-deep input.el-input__inner {
    padding-top: 15px;
    padding-left: 0;
  }

  ::v-deep input:focus + label, .has-text + label {
    font-weight: 700 !important;
    font-size: 12px !important;
    transform: translate3d(0, -0.8rem, 0) !important;
  }

  ::v-deep input:focus + label {
    color: $secondary;
  }

</style>
