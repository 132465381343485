/**
 * Validate a field.
 *
 * @param validators   Validator[]
 * @param value        string | boolean
 *
 * @return []:         Error[] || []
*/
const validate = (validators, value) => {
  const strategies = validators || [];
  const validatorResult = strategies.map(
    aStrategy => aStrategy(value),
  );
  return validatorResult.filter(x => typeof x === 'string');
};

export default validate;
