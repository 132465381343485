/*--------------------------------------------------
 * Unclassified misc functions
 * Ideally, should classify below into separate files
--------------------------------------------------- */

/**
 * Find all URLs in a text string.
 * Some data in NRL have this format (url in string)
 *
 * @return List: [URL]
*/
export const extractURLList = string => string.match(/\bhttps?:\/\/\S+/gi) || [];

/**
 * Find the first URL in a text string.
 * Some data in NRL have this format (url in string)
 *
 * @return String: URL
*/
export const extractURLFirst = string => extractURLList(string)[0];

/**
 * polyfill for `Object.fromEntries` for Edge.
 * A polyfill package can be used but it usually adds over 5KB so not needed so far as now
*/
export const fromEntries = iterable => [...iterable].reduce((obj, [key, val]) => {
  // eslint-disable-next-line no-param-reassign
  obj[key] = val;
  return obj;
}, {});

/**
 * convert url into hyperlink markup
 */
export const urlify = (text) => {
  // eslint-disable-next-line no-var
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, '<a target="_blank" href="$1">$1</a>');
};
