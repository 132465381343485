<template>
  <div class="form-control">
    <masked-input
        type="text"
        :name="field"
        v-model="inputVal"
        :mask="dateMask"
        :guide="false"
        @blur="$emit('blur')"
        placeholderChar="#"
        :required="!!required"
        :disabled="!!disabled"
        class="full-width button-transparent"
        :class="inputVal && inputVal !== 'Invalid date' ? 'has-text': null"
    />
    <label
    :for="field"
    class="no-block"
    :class="isWWC ? 'custom-label' : null">{{ placeholder }}</label>
    <div class="end-label">DD / MM / YYYY</div>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';

export default {
  name: 'DateInput',
  components: {
    MaskedInput,
  },
  props: {
    isWWC: Boolean,
    value: String,
    field: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text', // possible: [number, text, textarea]
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  data() {
    return {
      dateMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      inputVal: this.value || '',
    };
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-control {
    border: none;
    position: relative;
    padding: 0;

    input {
      padding: 25px 12px 6px 0 !important;
      height: 45px !important;
      font-size: 0.875rem;
      color: $secondary;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      box-shadow: none !important;
      border-bottom: solid 2px lightgrey;
      transition: border-color .5s;

      &:disabled {
        background-color: #F5F7FA;
      }
    }

    label {
      position: absolute;
      bottom: 3px;
      left: 0;
      color: #909090;
      font-weight: 300;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease-in-out;
    }

    .custom-label {
      position: absolute !important;
      bottom: 5px !important;
      left: 0 !important;
      color: #909090 !important;
      font-weight: 300 !important;
      -webkit-transform: translate3d(0, 0, 0) !important;
      transform: translate3d(0, 0, 0) !important;
      -webkit-transition: all 0.2s ease-in-out !important;
      transition: all 0.2s ease-in-out !important;
      font-size: 0.875rem;
    }

    .end-label {
      position: absolute;
      bottom: 5px;
      right: 0;
      color: #909090;
      font-weight: 300;
      visibility: visible;
      opacity: 1;
      transition: all 0.2s;
    }

  }

  input:focus + label, .has-text + label {
    font-weight: 700;
    font-size: 12px;
    transform: translate3d(0, -1.1rem, 0);
  }
  input:focus ~ .end-label, .has-text ~ .end-label {
    visibility: hidden;
    opacity: 0;
  }

  input:focus + label {
    color: $secondary !important;
  }

  input:focus + .custom-label {
    color: $secondary !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    -webkit-transform: translate3d(0, -1.1rem, 0) !important;
    transform: translate3d(0, -1.1rem, 0) !important;
  }

  .has-text ~ .custom-label {
    color: $secondary !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    -webkit-transform: translate3d(0, -1.1rem, 0) !important;
    transform: translate3d(0, -1.1rem, 0) !important;
  }

</style>
