<template>
  <el-radio-group v-model="inputVal" class="d-flex">
    <el-radio
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        class="w-50"
    >
      {{ option.label }}
    </el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: 'RadioSP',
  props: {
    options: Array,
    value: String,
    name: String,
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val);
    },
  },
  data() {
    return {
      inputVal: this.value,
    };
  },
};

</script>

<style lang="scss" scoped>
</style>
