<template>
  <div class="el-form-item">
    <div class="el-form-item__content">
      <el-select
        v-model="inputVal"
        class="form-control"
        :class="valueCheck ? 'has-text' : null"
        placeholder=""
        :disabled="disabled"
        @blur="$emit('blur')"
      >
        <el-option
          :id="option.value"
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        >
        </el-option>
      </el-select>
      <label :for="name">
        <span v-if="titleHtmlify" v-html="urlify(placeholder)"></span>
        <span v-else class="no-block">{{ placeholder }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { urlify } from "@/utils/misc";

export default {
  // https://stackoverflow.com/questions/47311936/v-model-and-child-components
  name: "SelectSP",
  props: {
    options: Array,
    value: [String, Boolean],
    name: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    titleHtmlify: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueCheck() {
      if (this.inputVal === false) return true;
      return !!this.inputVal;
    }
  },
  watch: {
    inputVal(val) {
      this.$emit("input", val);
    }
  },
  data() {
    return {
      urlify,
      inputVal: this.value
    };
  }
};
</script>
<style lang="scss">
li.el-select-dropdown__item {
  font-size: 0.875rem;
  &.selected {
    span {
      font-family: $fontBold;
    }

    &::before {
      border: none !important;
    }
  }
}
</style>

<style lang="scss" scoped>
// new animated for selects
::v-deep .el-select {
  width: 100%;

  + label {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 0.875rem;
    color: #909090;
    font-weight: 300;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
  }
}

::v-deep input.el-input__inner {
  padding-top: 15px;
  padding-left: 0;
}

::v-deep input:focus + label,
.has-text + label {
  font-weight: 700 !important;
  font-size: 12px !important;
  transform: translate3d(0, -0.8rem, 0) !important;
}

::v-deep input:focus + label {
  color: $secondary;
}
</style>
